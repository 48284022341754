import { AgGridReact } from 'ag-grid-react';
import axios from 'axios';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { Button, Panel, PanelGroup } from 'react-bootstrap';
import { ValidationCellEditor } from './scripts/validationCellEditor';
import { getCookie } from './App';

type RateCrosswalkGroup = {
  id: number;
  name: string;
  rate_crosswalks: any;
  upload_errors: any;
};

export const RateCrosswalkDetail = () => {
  axios.defaults.headers.common['X-CSRFTOKEN'] = getCookie('csrftoken');
  const [crosswalkGroup, setCrosswalkGroup] = useState<
    RateCrosswalkGroup | any
  >({});
  const [invalidEdit, setInvalidEdit] = useState(false);
  useEffect(() => {
    // group is a hidden span in the html template containing a json representation of the group
    const group = document.getElementById('group')?.innerHTML as string;
    setCrosswalkGroup(JSON.parse(group));
  }, []);
  const onCellEditRequest = useCallback(
    event => {
      const data = event.data;
      const field = event.colDef.field;
      const newValue = event.newValue;
      const oldItem = crosswalkGroup.rate_crosswalks.filter(
        row => row.id == data.id
      );
      if (!oldItem || !field) {
        return;
      }
      const newItem = { ...oldItem };
      newItem[0][field] = newValue;
      crosswalkGroup.rate_crosswalks = crosswalkGroup.rate_crosswalks.map(
        oldItem => (oldItem.id == newItem.id ? newItem : oldItem)
      );
      const rateCrosswalk = newItem[0];
      axios
        .post(`rate_crosswalk/${rateCrosswalk.id}/update/`, rateCrosswalk)
        .then(response => {
          const newRateCrosswalkGroup = JSON.parse(
            JSON.stringify(crosswalkGroup)
          );
          newRateCrosswalkGroup.rate_crosswalks = response.data;
          setCrosswalkGroup(newRateCrosswalkGroup);
        });
    },
    [crosswalkGroup]
  );

  const formatErrors = (errors: { [s: string]: any }) => {
    const propertyLabelMap = {
      procedure_code: 'Procedure Code',
      payer_type: 'Payer Type',
      rate: 'Rate'
    };
    const errList: any = [];
    for (const [key, value] of Object.entries(errors)) {
      value.map(errMsg =>
        errList.push({ column: propertyLabelMap[key], error: errMsg })
      );
    }
    return errList;
  };

  const formDataForAG = () => {
    const dataForTable: any = [];
    crosswalkGroup.upload_errors.forEach(error => {
      if (error.formData) {
        const tableDataObject = {};
        tableDataObject['procedure_code'] = error.formData.procedure_code;
        tableDataObject['rate'] = parseFloat(error.formData.rate);
        tableDataObject['payer_type'] = error.formData.payer_type;
        tableDataObject['errors'] = formatErrors(error.errors);
        tableDataObject['row'] = error.row;
        dataForTable.push(tableDataObject);
      }
    });
    return dataForTable;
  };

  const crosswalkUploadErrorDisplay = () => {
    const gridRef = useRef(null);

    const validateProcedureCode = code => {
      //alphanumeric characters only; minimum length 3, max 10
      const procedure_code_regex = new RegExp('^[a-zA-Z0-9]{3,10}$');
      return procedure_code_regex.test(code) ? true : false;
    };

    const validateRate = rate => {
      return rate > 0 && rate <= 99999.99 ? true : false;
    };

    const validatePayerType = payer => {
      const validPayerTypes = [
        'Commercial',
        'Dual-Eligible',
        'Managed Medicaid',
        'Managed Medicare',
        'Medicaid',
        'Other',
        'Traditional Medicaid',
        'Traditional Medicare',
        'Unknown',
        "VA / Other Gov't"
      ];
      return validPayerTypes.includes(payer) ? true : false;
    };

    const onResolve = useCallback((crosswalkGroupId, event) => {
      if (
        validateProcedureCode(event.data['procedure_code']) &&
        validateRate(event.data['rate']) &&
        validatePayerType(event.data['payer_type'])
      ) {
        event.data['resolve'] = true;
        const rowData = JSON.stringify(event.data);
        axios
          .post(`rate_crosswalk/${crosswalkGroupId}/handle-error/`, rowData)
          .then(response => {
            const newRateCrosswalkGroup = JSON.parse(
              JSON.stringify(response.data)
            );
            setCrosswalkGroup(newRateCrosswalkGroup);
          });
      } else {
        setInvalidEdit(true);
        setTimeout(() => {
          setInvalidEdit(false);
        }, 3000);
      }
    }, []);

    const onDismiss = (crosswalkGroupId, event) => {
      event.data['resolve'] = false;
      const rowData = JSON.stringify(event.data);
      axios
        .post(`rate_crosswalk/${crosswalkGroupId}/handle-error/`, rowData)
        .then(response => {
          const newRateCrosswalkGroup = JSON.parse(
            JSON.stringify(response.data)
          );
          setCrosswalkGroup(newRateCrosswalkGroup);
        });
    };

    if (crosswalkGroup.upload_errors) {
      return (
        <div>
          <PanelGroup accordion={true} id="upload-error-panel">
            <Panel eventKey="1" bsStyle="danger">
              <Panel.Heading>
                <Panel.Title toggle>
                  {crosswalkGroup.upload_errors.length} row(s) failed to upload
                </Panel.Title>
                {invalidEdit && (
                  <Panel.Title>
                    Please resolve existing errors before saving
                  </Panel.Title>
                )}
              </Panel.Heading>
              <Panel.Body collapsible>
                <div
                  id="upload-error-grid"
                  className="ag-theme-alpine"
                  style={{ height: 400 }}
                >
                  <AgGridReact
                    ref={gridRef}
                    rowData={formDataForAG()}
                    columnDefs={[
                      {
                        field: 'procedure_code',
                        headerName: 'Procedure Code',
                        cellRenderer: 'agGroupCellRenderer',
                        sortable: true,
                        sort: 'asc',
                        filter: 'agTextColumnFilter',
                        filterParams: {
                          buttons: ['reset']
                        },
                        editable: true,
                        cellEditor: ValidationCellEditor,
                        cellEditorParams: {
                          maxLength: 10
                        }
                      },
                      {
                        field: 'rate',
                        headerName: 'Rate',
                        sortable: true,
                        filter: 'agTextColumnFilter',
                        valueFormatter: params =>
                          parseFloat(params.value).toFixed(2),
                        filterParams: {
                          buttons: ['reset']
                        },
                        editable: true,
                        cellEditor: 'agNumberCellEditor',
                        cellEditorParams: {
                          precision: 2,
                          preventStepping: true,
                          min: 0,
                          max: 99999.99
                        }
                      },
                      {
                        field: 'payer_type',
                        headerName: 'Payer Type',
                        sortable: true,
                        filter: 'agTextColumnFilter',
                        filterParams: {
                          buttons: ['reset']
                        },
                        editable: true,
                        cellEditor: 'agRichSelectCellEditor',
                        cellEditorPopup: true,
                        cellEditorParams: {
                          values: [
                            'Commercial',
                            'Dual-Eligible',
                            'Managed Medicaid',
                            'Managed Medicare',
                            'Medicaid',
                            'Other',
                            'Traditional Medicaid',
                            'Traditional Medicare',
                            'Unknown',
                            "VA / Other Gov't"
                          ]
                        }
                      },
                      {
                        field: 'dismiss',
                        headerName: '',
                        cellRenderer: () => (
                          //@ts-ignore
                          <Button variant="primary" size="sm">
                            Dismiss
                          </Button>
                        ),
                        onCellClicked: event =>
                          onDismiss(crosswalkGroup.id, event)
                      },
                      {
                        field: 'resolve',
                        headerName: '',
                        cellRenderer: () => (
                          //@ts-ignore
                          <Button variant="primary" size="sm">
                            Save Changes
                          </Button>
                        ),
                        onCellClicked: event =>
                          onResolve(crosswalkGroup.id, event)
                      }
                    ]}
                    pagination={true}
                    masterDetail={true}
                    detailRowHeight={200}
                    detailCellRendererParams={{
                      detailGridOptions: {
                        columnDefs: [
                          { field: 'column', headerName: 'Column', flex: 1 },
                          {
                            field: 'error',
                            headerName: 'Error',
                            flex: 3
                          }
                        ]
                      },
                      getDetailRowData: params => {
                        params.successCallback(params.data.errors);
                      }
                    }}
                  ></AgGridReact>
                </div>
              </Panel.Body>
            </Panel>
          </PanelGroup>
        </div>
      );
    }
    return '';
  };

  return (
    <>
      <div className="col-sm-9">
        <div className="panel panel-default">
          <div className="panel-heading">
            <h4 className="panel-title">Rate Crosswalk Group Detail</h4>
          </div>
          <div className="panel-body">
            <ol className="breadcrumb">
              <li>
                <a href="/account/rate_crosswalk_groups/">
                  Rate Crosswalk Groups
                </a>
              </li>
              <li className="active">{crosswalkGroup.name}</li>
            </ol>
            {crosswalkUploadErrorDisplay()}
            <div className="ag-theme-alpine" style={{ height: 400 }}>
              <AgGridReact
                rowData={crosswalkGroup.rate_crosswalks}
                columnDefs={[
                  {
                    field: 'procedure_code',
                    headerName: 'Procedure Code',
                    sortable: true,
                    sort: 'asc',
                    filter: 'agTextColumnFilter',
                    filterParams: {
                      buttons: ['reset']
                    },
                    flex: 2,
                    editable: true,
                    cellEditor: ValidationCellEditor,
                    cellEditorParams: {
                      maxLength: 10
                    }
                  },
                  {
                    field: 'rate',
                    headerName: 'Rate',
                    sortable: true,
                    filter: 'agTextColumnFilter',
                    filterParams: {
                      buttons: ['reset']
                    },
                    flex: 2,
                    valueFormatter: params =>
                      parseFloat(params.value).toFixed(2),
                    editable: true,
                    cellEditor: 'agNumberCellEditor',
                    cellEditorParams: {
                      precision: 2,
                      preventStepping: true,
                      min: 0,
                      max: 99999.99
                    }
                  },
                  {
                    field: 'payer_type',
                    headerName: 'Payer Type',
                    sortable: true,
                    filter: 'agTextColumnFilter',
                    filterParams: {
                      buttons: ['reset']
                    },
                    flex: 2,
                    editable: true,
                    cellEditor: 'agRichSelectCellEditor',
                    cellEditorPopup: true,
                    cellEditorParams: {
                      values: [
                        'Commercial',
                        'Dual-Eligible',
                        'Managed Medicaid',
                        'Managed Medicare',
                        'Medicaid',
                        'Other',
                        'Traditional Medicaid',
                        'Traditional Medicare',
                        'Unknown',
                        "VA / Other Gov't"
                      ]
                    }
                  }
                ]}
                rowClass={'alt-row'}
                pagination={true}
                suppressAggFuncInHeader={true}
                readOnlyEdit={true}
                onCellEditRequest={onCellEditRequest}
              ></AgGridReact>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
