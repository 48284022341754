import React, { useEffect, useState } from 'react';
import axios from 'axios';

import { getCookie } from './libs/djangoCookie';
import Select from 'react-select';

interface OpensearchGenericTypeaheadProps {
  id: string;
  isMulti: boolean;
  columnNames: string;
  database: string;
  schema: string;
  table_name: string;
  placeholder: string;
  value: OptionType[] | { value: string | null; label: string | null };
  fetchBlank?: boolean;
  onChange;
}

type OptionType = {
  value: string;
  label: string;
};

const BASE_URL = `/api/typeaheads`;
const noOptionsMessage = () => 'No Matching Values';
const closeMenuOnSelect = true;
export const OpensearchGenericTypeahead: React.FC<OpensearchGenericTypeaheadProps> = ({
  id,
  isMulti,
  columnNames,
  database,
  schema,
  table_name,
  placeholder,
  value,
  onChange,
  fetchBlank = true
}) => {
  const [inputValue, setInputValue] = useState('');
  const [options, setOptions] = useState([]);
  const [isLoading, setLoading] = useState(false);

  // Set initial options when column changes
  useEffect(() => {
    setOptions([]);
    if (columnNames.length === 0) {
      setInputValue('');
      return;
    }
    fetchOptions('');
  }, [columnNames]);

  // fetch options when user types
  useEffect(() => {
    setOptions([]);
    if (columnNames.length === 0 || !fetchBlank) {
      return;
    }
    fetchOptions(inputValue);
  }, [inputValue]);

  const fetchOptions = async (inputValue: string) => {
    setLoading(true);

    const url = new URL(
      `${BASE_URL}/${database}/${schema}/${table_name}/`,
      window.location.origin
    );
    url.searchParams.append('q', inputValue);
    url.searchParams.append('column_names', columnNames);

    try {
      const response = await axios.get(url.toString(), {
        headers: {
          'X-CSRFToken': getCookie('csrftoken')
        }
      });
      const results = response.data
        .map(({ value }) => {
          return { value, label: value };
        })
        .sort((a: OptionType, b: OptionType) => a.label.localeCompare(b.label));

      setOptions(results);
      setLoading(false);
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
    } catch (error) {
      setOptions([]);
      setLoading(false);
    }
  };

  return (
    <Select
      id={id}
      className="filter-select"
      styles={{
        control: styles => ({
          ...styles,
          overflowY: 'scroll',
          maxHeight: '6rem'
        })
      }}
      isMulti={isMulti}
      onChange={onChange}
      options={options}
      value={value}
      placeholder={placeholder}
      noOptionsMessage={noOptionsMessage}
      closeMenuOnSelect={closeMenuOnSelect}
      isLoading={isLoading}
      onInputChange={(newValue: string) => setInputValue(newValue)}
    />
  );
};
