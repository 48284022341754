import { AgGridReact } from 'ag-grid-react';
import axios from 'axios';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { Button, Panel, PanelGroup } from 'react-bootstrap';
import { getCookie } from './App';
import {
  CellClickedEvent,
  ColDef,
  ICellRendererParams
} from 'ag-grid-community';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faDownload } from '@fortawesome/free-solid-svg-icons';
import { IconProp } from '@fortawesome/fontawesome-svg-core';

type UploadError = {
  row?: number;
  column?: string;
  error: string;
  row_data?: Facility;
};

type FacilityRoster = {
  id: number;
  name: string;
  providers: Facility[];
  upload_errors: UploadError[] | [];
};

type Facility = {
  id: number;
  facility_npi: string;
  in_network: string;
  facility_name: string;
  affiliation: string;
  address_line_1: string;
  address_line_2: string;
  city: string;
  state: string;
  zip_code: string;
  facility_type: string;
  cms_id: string;
  state_id_1: string;
  state_id_2: string;
};

export const FacilityRosterDetail = () => {
  axios.defaults.headers.common['X-CSRFTOKEN'] = getCookie('csrftoken');
  const gridRef = useRef<AgGridReact>(null);
  const errorGridRef = useRef<AgGridReact>(null);
  const [roster, setRoster] = useState<FacilityRoster | any>({});
  const [rowData, setRowData] = useState();
  const [inputRow, setInputRow] = useState({});
  const [existingNPIs, setExistingNPIs] = useState<string[]>([]);

  const validateNPI = (npi: string) => {
    if (!npi) {
      return false;
    }
    // 10 digits
    const npiRegex = new RegExp('^[0-9]{10}$');
    const isCorrectFormat = npiRegex.test(npi);
    return isCorrectFormat && !existingNPIs.includes(npi);
  };
  const validateInNetwork = (inNetwork: string) => {
    if (!inNetwork) {
      return true;
    }
    // 1 character, either Y or N
    const inNetworkRegex = new RegExp('^[YN]{1}$');
    return inNetworkRegex.test(inNetwork);
  };
  const validateState = (state: string) => {
    if (!state) {
      return true;
    }
    const stateRegex = new RegExp('^[A-Z]{2}$');
    return stateRegex.test(state);
  };
  const validateZipcode = (zipcode: string) => {
    if (!zipcode) {
      return true;
    }
    const zipcodeRegex = new RegExp('^[0-9]{5}$');
    return zipcodeRegex.test(zipcode);
  };
  const validateStateId = (stateId: string) => {
    if (!stateId) {
      return true;
    }
    const stateIdRegex = new RegExp('^[A-Za-z0-9]{1,20}$');
    return stateIdRegex.test(stateId);
  };
  const validateCMSId = (CMSId: string) => {
    if (!CMSId) {
      return true;
    }
    const CMSIdRegex = new RegExp('^[0-9]{6}$');
    return CMSIdRegex.test(CMSId);
  };
  const propertyLabelMap = {
    facility_npi: {
      headerName: 'Facility NPI',
      field: 'facility_npi',
      isValid: true,
      validatorFn: validateNPI,
      editorParams: {
        maxLength: 10
      },
      tooltipMessage:
        'NPI must be 10 digits and can only appear once in the roster'
    },
    in_network: {
      headerName: 'In Network',
      field: 'in_network',
      validatorFn: validateInNetwork,
      editorParams: {
        maxLength: 1
      },
      tooltipMessage: "In Network must be 'Y' or 'N'"
    },
    facility_name: {
      headerName: 'Facility Name',
      field: 'facility_name',
      editorParams: {
        maxLength: 255
      },
      tooltipMessage: 'Facility Name must be 255 characters or less'
    },
    affiliation: {
      headerName: 'Affiliation',
      field: 'affiliation',
      editorParams: {
        maxLength: 255
      },
      tooltipMessage: 'Affiliation must be 255 characters or less'
    },
    address_line_1: {
      headerName: 'Address Line 1',
      field: 'address_line_1',
      editorParams: {
        maxLength: 255
      },
      tooltipMessage: 'Address Line 1 must be 255 characters or less'
    },
    address_line_2: {
      headerName: 'Address Line 2',
      field: 'address_line_2',
      editorParams: {
        maxLength: 255
      },
      tooltipMessage: 'Address Line 2 must be 255 characters or less'
    },
    city: {
      headerName: 'City',
      field: 'city',
      editorParams: {
        maxLength: 50
      },
      tooltipMessage: 'City must be 50 characters or less'
    },
    state: {
      headerName: 'State',
      field: 'state',
      validatorFn: validateState,
      editorParams: {
        maxLength: 2
      },
      tooltipMessage: 'State Abbreviation, 2 characters'
    },
    zip_code: {
      headerName: 'ZIP Code',
      field: 'zip_code',
      validatorFn: validateZipcode,
      editorParams: {
        maxLength: 5
      },
      tooltipMessage: 'ZIP Code must be 5 digits'
    },
    facility_type: {
      headerName: 'Facility Type',
      field: 'facility_type',
      editorParams: {
        maxLength: 50
      },
      tooltipMessage: 'Facility Type must be 50 characters or less'
    },
    cms_id: {
      headerName: 'CMS Id',
      field: 'cms_id',
      validatorFn: validateCMSId,
      editorParams: {
        maxLength: 6
      },
      tooltipMessage: 'CMS Id must be 6 digits'
    },
    state_id_1: {
      headerName: 'State Id 1',
      field: 'state_id_1',
      validatorFn: validateStateId,
      editorParams: {
        maxLength: 20
      },
      tooltipMessage: 'State Id must be 20 characters or less'
    },
    state_id_2: {
      headerName: 'State Id 2',
      field: 'state_id_2',
      validatorFn: validateStateId,
      editorParams: {
        maxLength: 20
      },
      tooltipMessage: 'State Id must be 20 characters or less'
    }
  };

  const [defaultColDef] = useState<ColDef>({
    editable: true,
    sortable: true,
    filter: 'agTextColumnFilter',
    filterParams: {
      buttons: ['reset']
    }
  });

  const syncValidator = (
    newValue,
    validationFn,
    field,
    _onSuccess,
    _onFail
  ) => {
    if (!validationFn) {
      const maxLength = propertyLabelMap[field].editorParams.maxLength;
      if (maxLength) {
        if (newValue.length > maxLength) {
          _onFail();
        } else {
          _onSuccess();
        }
      } else {
        _onSuccess();
      }
    } else {
      if (validationFn(newValue)) {
        _onSuccess();
      } else {
        _onFail();
      }
    }
  };
  const _onSuccess = params => () => {
    const data = JSON.parse(JSON.stringify(params.data));
    const field = params.colDef.field;
    data[field] = {
      ...data[field],
      value: params.newValue,
      isValid: true
    };
    data.rowData[field] = params.newValue;
    params.api.applyTransaction({ update: [data] });
    const allFieldsValid = Object.keys(propertyLabelMap)
      .map(field => data[field].isValid)
      .every(item => item == true);
    if (allFieldsValid) {
      const payload = {
        resolve: true,
        row: data['row'],
        rowData: data['rowData']
      };
      axios.post('handle-error/', payload).then(response => {
        const newRoster = JSON.parse(JSON.stringify(response.data));
        const rowData = rowDataFromProviders(newRoster.providers);
        const existingNPIs = newRoster.providers.map(
          provider => provider.facility_npi
        );
        setRoster(newRoster);
        setExistingNPIs(existingNPIs);
        setInputRow(getInputRow());
        setRowData(rowData);
      });
    }
  };
  const _onFail = params => () => {
    const data = JSON.parse(JSON.stringify(params.data));
    const field = params.colDef.field;
    data[field] = {
      ...data[field],
      value: params.newValue,
      isValid: false
    };
    params.api.applyTransaction({ update: [data] });
  };
  const syncValueSetter = (validateFn, field) => params => {
    if (validateFn == validateInNetwork) {
      params.newValue = params.newValue.toUpperCase();
    }
    syncValidator(
      params.newValue,
      validateFn,
      field,
      _onSuccess(params),
      _onFail(params)
    );
    return false;
  };

  const createColumnDef = (field, errorDisplay = false) => {
    const colDef = {
      field,
      headerName: propertyLabelMap[field].headerName,
      cellEditorParams: propertyLabelMap[field].editorParams,
      valueGetter: params => params.data?.[field]?.value,
      cellStyle: params => {
        const styles = { fontSize: '14px' };
        if (params.node.rowPinned) {
          styles['fontWeight'] = 'bold';
          styles['fontStyle'] = 'italic';
        }
        if (!params.data?.[field]?.isValid) {
          styles['color'] = 'red';
        }
        return styles;
      }
    };
    if (errorDisplay) {
      colDef['valueSetter'] = syncValueSetter(
        propertyLabelMap[field].validatorFn,
        field
      );
      colDef['tooltipValueGetter'] = () =>
        propertyLabelMap[field].tooltipMessage;
      return colDef;
    }
    return colDef;
  };
  const getColumnDefs = (errorDisplay = false) =>
    Object.keys(propertyLabelMap).map(field =>
      createColumnDef(field, errorDisplay)
    ) as ColDef[];
  const rowDataFromProviders = (providers: Facility[]) => {
    const rowData: any = [];
    providers.forEach(provider => {
      const dataObj = {};
      Object.keys(provider).forEach(key => {
        dataObj[key] = {
          field: key,
          value: provider[key],
          isValid: true
        };
      });
      rowData.push(dataObj);
    });
    return rowData as any;
  };
  const getInputRow = () => {
    const inputRow = {};
    Object.keys(propertyLabelMap).forEach(field => {
      inputRow[field] = {
        field,
        value: `${propertyLabelMap[field].headerName}...`,
        isValid: true
      };
    });
    return inputRow;
  };

  useEffect(() => {
    // roster is a hidden span in the html template containing a json representation of the roster
    const htmlRoster = document.getElementById('facility-roster')
      ?.innerHTML as string;
    const roster = JSON.parse(htmlRoster);
    const rowData = rowDataFromProviders(roster.providers);
    const inputRow = getInputRow();
    const existingNPIs = roster.providers.map(
      provider => provider.facility_npi
    );
    setRoster(roster);
    setInputRow(inputRow);
    setRowData(rowData);
    setExistingNPIs(existingNPIs);
  }, []);

  const getRowId = params => params.data?.id?.value.toString();
  const getErrorRowId = params => params.data?.row.toString();

  const onCellEditRequest = useCallback(params => {
    const field = params.colDef.field;
    if (!field) {
      return;
    }
    const newValue =
      field == 'in_network' ? params.newValue.toUpperCase() : params.newValue;
    const validationFn = propertyLabelMap[field].validatorFn;
    const newItem = JSON.parse(JSON.stringify(params.data));
    newItem[field].value = newValue;
    newItem[field].isValid = validationFn ? validationFn(newValue) : true;
    if (params.rowPinned) {
      const rowNode = gridRef.current?.api.getPinnedTopRow(0);
      rowNode?.updateData(newItem);
      setInputRow(newItem);
      return;
    }
    params.api.applyTransaction({ update: [newItem] });
    const facility = {} as Facility;
    Object.keys(newItem).forEach(
      field => (facility[field] = newItem[field].value)
    );
    const allFieldsValid = Object.keys(propertyLabelMap)
      .map(field => newItem[field].isValid)
      .every(item => item == true);
    if (allFieldsValid) {
      axios.post(`facilities/${facility.id}/edit`, facility).then(response => {
        const newRoster = response.data;
        const rowData = rowDataFromProviders(newRoster.providers);
        const existingNPIs = newRoster.providers.map(item => item.facility_npi);
        setRoster(newRoster);
        setRowData(rowData);
        setExistingNPIs(existingNPIs);
      });
    }
  }, []);

  const onCreate = useCallback(params => {
    const newItem = params.data;
    const facility = {} as Facility;
    Object.keys(newItem).forEach(field => {
      const placeholder = `${propertyLabelMap[field].headerName}...`;
      facility[field] =
        newItem[field].value !== placeholder ? newItem[field].value : null;
    });
    axios.post('facilities/create', facility).then(response => {
      const newRoster = JSON.parse(JSON.stringify(response.data));
      const rowData = rowDataFromProviders(newRoster.providers);
      const existingNPIs = newRoster.providers.map(item => item.facility_npi);
      setRoster(newRoster);
      setInputRow(getInputRow());
      setRowData(rowData);
      setExistingNPIs(existingNPIs);
    });
  }, []);
  const onDismiss = (event: CellClickedEvent<any, any>) => {
    event.data['resolve'] = false;
    axios.post('handle-error/', event.data).then(response => {
      const newRoster = JSON.parse(JSON.stringify(response.data));
      const rowData = rowDataFromProviders(newRoster.providers);
      const existingNPIs = newRoster.providers.map(
        provider => provider.facility_npi
      );
      setRoster(newRoster);
      setRowData(rowData);
      setExistingNPIs(existingNPIs);
    });
  };
  const onDismissAll = () => {
    axios.post('clear-errors/').then(response => {
      const newRoster = JSON.parse(JSON.stringify(response.data));
      const rowData = rowDataFromProviders(newRoster.providers);
      const existingNPIs = newRoster.providers.map(
        provider => provider.facility_npi
      );
      setRoster(newRoster);
      setRowData(rowData);
      setExistingNPIs(existingNPIs);
    });
  };
  const onDelete = useCallback(event => {
    if (event.rowPinned == 'top') {
      // clear any changes in input row
      setInputRow(getInputRow());
      return;
    }
    axios
      .delete(`facilities/${event.data['id'].value}/delete`)
      .then(response => {
        const newRoster = JSON.parse(JSON.stringify(response.data));
        const rowData = rowDataFromProviders(newRoster.providers);
        const existingNPIs = newRoster.providers.map(
          provider => provider.facility_npi
        );
        setRoster(newRoster);
        setRowData(rowData);
        setExistingNPIs(existingNPIs);
      });
  }, []);

  const errorRowData = () => {
    const dataForTable: any = [];
    roster.upload_errors.forEach(error => {
      const data = {
        rowData: error.row_data,
        row: error.row
      };
      Object.entries(error.row_data).forEach(([key, value]) => {
        let isValid = true;
        const maxLength = propertyLabelMap[key]?.editorParams?.maxLength;
        if (key == 'roster_id') {
          isValid = true;
        } else if (propertyLabelMap[key].validatorFn) {
          isValid = propertyLabelMap[key].validatorFn(value);
        } else if (maxLength && value) {
          //@ts-ignore
          isValid = value.length <= maxLength;
        }

        data[key] = {
          field: key,
          value: value,
          isValid: isValid
        };
      });
      dataForTable.push(data);
    });
    return dataForTable.sort((a, b) => a.row - b.row);
  };

  const rosterUploadErrorDisplay = () => {
    const errorDisplay = true;
    const errorColumnDefs = getColumnDefs(errorDisplay);
    // @ts-ignore
    errorColumnDefs.find(columnDef => columnDef.field === 'facility_npi')[
      'cellRenderer'
    ] = 'agGroupCellRenderer';
    const dismissButton: ColDef = {
      field: 'dismiss',
      headerName: '',
      cellRenderer: () => (
        //@ts-ignore
        <Button className="btn-danger">Dismiss</Button>
      ),
      onCellClicked: event => onDismiss(event),
      suppressHeaderFilterButton: true,
      suppressHeaderMenuButton: true,
      suppressColumnsToolPanel: true
    };
    errorColumnDefs.push(dismissButton);

    if (roster.upload_errors?.length > 0) {
      const rowsWithErrors = new Set(
        roster.upload_errors.map(error => error.row)
      );
      return (
        <div>
          <PanelGroup accordion={true} id="upload-error-panel">
            <Panel eventKey="1" bsStyle="danger">
              <Panel.Heading>
                <div
                  style={{ display: 'flex', justifyContent: 'space-between' }}
                >
                  <Panel.Title
                    toggle
                    style={{ display: 'flex', alignItems: 'center' }}
                  >
                    {rowsWithErrors.size} row(s) failed to upload
                  </Panel.Title>
                  <Button className="btn-danger" onClick={onDismissAll}>
                    Dismiss All
                  </Button>
                </div>
              </Panel.Heading>
              <Panel.Body collapsible>
                <div
                  id="upload-error-grid"
                  className="ag-theme-alpine"
                  style={{
                    height: 400
                  }}
                >
                  <AgGridReact
                    getRowId={getErrorRowId}
                    ref={errorGridRef}
                    rowData={errorRowData()}
                    columnDefs={errorColumnDefs}
                    defaultColDef={defaultColDef}
                    tooltipShowDelay={0}
                    pagination={true}
                    sideBar={{
                      toolPanels: [
                        {
                          id: 'columns',
                          labelDefault: 'Columns',
                          labelKey: 'columns',
                          iconKey: 'columns',
                          toolPanel: 'agColumnsToolPanel',
                          toolPanelParams: {
                            suppressRowGroups: true,
                            suppressValues: true,
                            suppressPivots: true,
                            suppressPivotMode: true,
                            suppressColumnFilter: true,
                            suppressColumnSelectAll: true,
                            suppressColumnExpandAll: true
                          }
                        }
                      ],
                      defaultToolPanel: ''
                    }}
                  ></AgGridReact>
                </div>
              </Panel.Body>
            </Panel>
          </PanelGroup>
        </div>
      );
    }
    return '';
  };

  if (!roster.providers) {
    return <div>Loading...</div>;
  } else {
    const getDeleteCellRenderer = params => {
      let btnTxt = 'Delete Facility';
      if (params.node.rowPinned == 'top') {
        btnTxt = 'Reset';
      }
      return <Button className="btn-danger">{btnTxt}</Button>;
    };

    let columnDefsCopy = getColumnDefs();
    const buttonColumnDefs: ColDef[] = [
      {
        field: 'create',
        headerName: '',
        cellRenderer: (params: ICellRendererParams) =>
          params.node.rowPinned ? (
            <Button className="btn-primary">Create Facility</Button>
          ) : null,
        onCellClicked: event => onCreate(event),
        suppressHeaderFilterButton: true,
        suppressHeaderMenuButton: true,
        suppressColumnsToolPanel: true
      },
      {
        field: 'delete',
        headerName: '',
        cellRenderer: (params: ICellRendererParams) => {
          return getDeleteCellRenderer(params);
        },
        onCellClicked: event => onDelete(event),
        suppressHeaderFilterButton: true,
        suppressHeaderMenuButton: true,
        suppressColumnsToolPanel: true
      }
    ];
    columnDefsCopy = columnDefsCopy.concat(buttonColumnDefs);
    return (
      <>
        <div className="col-sm-9">
          <div className="panel panel-default">
            <div className="panel-heading">
              <h4 className="panel-title">Facility Roster Detail</h4>
            </div>
            <div className="panel-body">
              <ol className="breadcrumb">
                <li>
                  <a href="/account/facility_roster/corporate/">
                    Facility Rosters
                  </a>
                </li>
                <li className="active">{roster.name}</li>
              </ol>
              {rosterUploadErrorDisplay()}
              <div style={{ marginBottom: '1em' }}>
                <Button
                  title="Download CSV"
                  className="primary btn-primary"
                  onClick={() => gridRef.current?.api.exportDataAsCsv()}
                >
                  {' '}
                  Download Facility Roster{' '}
                  <FontAwesomeIcon icon={faDownload as IconProp} />
                </Button>
              </div>
              <div className="ag-theme-alpine" style={{ height: 400 }}>
                <AgGridReact
                  defaultCsvExportParams={{ allColumns: true }}
                  getRowId={getRowId}
                  ref={gridRef}
                  rowData={rowData}
                  pinnedTopRowData={[inputRow]}
                  columnDefs={columnDefsCopy}
                  defaultColDef={defaultColDef}
                  sideBar={{
                    toolPanels: [
                      {
                        id: 'columns',
                        labelDefault: 'Columns',
                        labelKey: 'columns',
                        iconKey: 'columns',
                        toolPanel: 'agColumnsToolPanel',
                        toolPanelParams: {
                          suppressRowGroups: true,
                          suppressValues: true,
                          suppressPivots: true,
                          suppressPivotMode: true,
                          suppressColumnFilter: true,
                          suppressColumnSelectAll: true,
                          suppressColumnExpandAll: true
                        }
                      }
                    ],
                    defaultToolPanel: ''
                  }}
                  rowClass={'alt-row'}
                  pagination={true}
                  suppressAggFuncInHeader={true}
                  readOnlyEdit={true}
                  onCellEditRequest={onCellEditRequest}
                ></AgGridReact>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
};
