import React, { useEffect, useState } from 'react';
import { formatNumberToHaveCommas } from './libs/formatters';
import { AgGridReact } from 'ag-grid-react';
import { ColDef, ValueFormatterParams } from 'ag-grid-community';
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-alpine.css';
import { accountApi, handleServiceUnavailable } from './libs/api';
import {
  ApcdCoverageMetricsNational,
  ApcdCoverageMetricsNationalResponse
} from './libs/types';
import { useQuery } from 'react-query';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSpinner } from '@fortawesome/free-solid-svg-icons';
import { IconProp } from '@fortawesome/fontawesome-svg-core';

export const ApcdTypeOneTwoCoverageMetricsNational: React.FC = () => {
  const [rowData, setRowData] = useState<
    ApcdCoverageMetricsNational[] | undefined
  >(undefined);
  const { data, isLoading } = useGetApcdCoverageMetricsNational();

  useEffect(() => {
    if (data && data.length > 0) {
      setRowData(data);
    }
  }, [data]);

  function ratioToPercentFormatter(params: ValueFormatterParams) {
    if (!params.value || params.value === 0) return '';
    return '' + Math.round(params.value * 100) + '%';
  }

  const columnDefs = [
    {
      headerName: 'Number of Patients',
      field: 'num_patients',
      valueFormatter: v => formatNumberToHaveCommas(v.value),
      cellStyle: { textAlign: 'center' },
      flex: 1
    },
    {
      headerName: 'Total Medicare Beneficiaries seen',
      field: 'tot_benes',
      valueFormatter: v => formatNumberToHaveCommas(v.value),
      cellStyle: { textAlign: 'center' },
      flex: 1
    },
    {
      headerName: 'Apcd to CMS Ratio',
      valueGetter: params => {
        return {
          num_patients: params.data.avg_num_patients,
          tot_benes: params.data.avg_tot_benes,
          toString: () => params.data.num_patients / params.data.tot_benes
        };
      },
      valueFormatter: ratioToPercentFormatter,
      cellStyle: { textAlign: 'center' },
      flex: 1
    }
  ];

  const onGridReady = params => {
    params.api.sizeColumnsToFit();
  };

  return (
    <div style={{ width: '100%', marginBottom: '2rem' }}>
      <h4>National</h4>
      <div
        className="ag-theme-alpine apcd-metrics-national-grid"
        style={{ width: '100%' }}
      >
        <AgGridReact
          rowData={rowData}
          columnDefs={columnDefs as ColDef[]}
          domLayout="autoHeight"
          suppressAggFuncInHeader={true}
          onGridReady={onGridReady}
          loading={isLoading}
          loadingOverlayComponent={() => (
            <FontAwesomeIcon icon={faSpinner as IconProp} spin size="lg" />
          )}
        />
      </div>
    </div>
  );
};

export const useGetApcdCoverageMetricsNational = () => {
  return useQuery({
    queryKey: ['account', 'apcd_coverage_metrics_national'],
    queryFn: () => getApcdCoverageMetricsNational()
  });
};

export const getApcdCoverageMetricsNational = async () => {
  const res = await accountApi.get<ApcdCoverageMetricsNationalResponse>(
    `dataset/apcd_coverage_metrics_national/`
  );
  if (!res) {
    throw Error('Account API failed to respond. Please try again.');
  }
  handleServiceUnavailable(res);
  return res.data.coverage_metrics_national;
};
