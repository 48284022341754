import React, { useEffect, useRef, useState } from 'react';
import { formatNumberToHaveCommas } from './libs/formatters';
import { AgGridReact } from 'ag-grid-react';
import { OpensearchGenericTypeahead } from './OpensearchGenericTypeahead';
import {
  ColDef,
  SizeColumnsToContentStrategy,
  ValueFormatterParams
} from 'ag-grid-community';
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-alpine.css';
import { accountApi, handleServiceUnavailable } from './libs/api';
import { ApcdCoverageMetrics, ApcdCoverageMetricsResponse } from './libs/types';
import { useQuery } from 'react-query';
import { faSpinner } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IconProp } from '@fortawesome/fontawesome-svg-core';

export const ApcdTypeOneTwoCoverageMetrics: React.FC = () => {
  const gridApiRef = useRef<AgGridReact>(null);

  const [selectedState, setSelectedState] = useState({
    value: null,
    label: null
  });
  const [selectedCounties, setSelectedCounties] = useState([]);
  const [
    selectedProviderSpecialties,
    setSelectedProviderSpecialties
  ] = useState([]);
  const [rowData, setRowData] = useState<ApcdCoverageMetrics[] | undefined>(
    undefined
  );
  const [loading, setLoading] = useState(true);
  const handleStateChange = selectedOption => {
    setSelectedState(selectedOption);
  };
  const handleCountyChange = selectedOptions => {
    setSelectedCounties(selectedOptions);
  };

  const handleProviderSpecialtyChange = selectedOption => {
    setSelectedProviderSpecialties(selectedOption);
  };
  const requiredFilter = { column: 'state', value: selectedState.value };
  const optionalFilters = [
    { column: 'county', value: selectedCounties },
    {
      column: 'main_individual_provider_primary_specialty',
      value: selectedProviderSpecialties
    }
  ];
  const { data, refetch } = useGetApcdCoverageMetricsByState(
    requiredFilter,
    optionalFilters
  );

  useEffect(() => {
    if (selectedState.value !== null) {
      refetch();
      setRowData([]);
      setLoading(true);
    }
  }, [selectedState, selectedCounties, selectedProviderSpecialties]);

  useEffect(() => {
    if (data?.length === 0) {
      setRowData([]);
      setLoading(false);
    }
    if (data && data.length > 0) {
      setRowData(data);
      setLoading(false);
    }
  }, [data]);

  function ratioToPercentFormatter(params: ValueFormatterParams) {
    if (!params.value || params.value === 0) return '';
    return '' + Math.round(params.value * 100) + '%';
  }

  function blankAffiliationFormatter(affiliation, colDefType) {
    if (!affiliation && colDefType === 'hosptial') return 'UNKNOWN AFFILIATION';
    else if (!affiliation && colDefType === 'office') return 'HOSPITAL ONLY';
  }

  const columnDefs = [
    {
      headerName: 'County',
      field: 'main_individual_provider_computed_county',
      rowGroup: true,
      enableRowGroup: true,
      hide: true,
      filter: 'agTextColumnFilter'
    },
    {
      headerName: 'Hospital Affiliation',
      field: 'hos_aff',
      rowGroup: true,
      enableRowGroup: true,
      hide: true,
      filter: 'agTextColumnFilter',
      valueFormatter: v => blankAffiliationFormatter(v.value, 'hosptial')
    },
    {
      headerName: 'Office Affiliation',
      field: 'off_aff',
      rowGroup: true,
      enableRowGroup: true,
      hide: true,
      filter: 'agTextColumnFilter',
      valueFormatter: v => blankAffiliationFormatter(v.value, 'office')
    },
    {
      headerName: 'Provider Speciality',
      field: 'main_individual_provider_primary_specialty',
      rowGroup: true,
      enableRowGroup: true,
      hide: true,
      filter: 'agTextColumnFilter'
    },
    {
      headerName: 'Provider Name',
      field: 'main_individual_provider_name',
      filter: 'agTextColumnFilter'
    },
    {
      headerName: 'Number of Patients',
      field: 'num_patients',
      aggFunc: 'sum',
      valueFormatter: v => formatNumberToHaveCommas(v.value),
      cellStyle: { textAlign: 'right' },
      sort: 'desc'
    },
    {
      headerName: 'Number of Beneficiaries',
      field: 'tot_benes',
      aggFunc: 'sum',
      valueFormatter: v => formatNumberToHaveCommas(v.value),
      cellStyle: { textAlign: 'right' }
    },
    {
      headerName: 'Apcd to CMS Ratio',
      field: 'apcd_to_cms_ratio',
      valueGetter: params => {
        if (!params.node.group) {
          return {
            num_patients: params.data.num_patients,
            tot_benes: params.data.tot_benes,
            toString: () =>
              params.data.num_patients && params.data.tot_benes
                ? params.data.num_patients / params.data.tot_benes
                : 0
          };
        }
      },
      aggFunc: params => {
        let patientsSum = 0;
        let beneSum = 0;
        params.values.forEach(value => {
          if (value && value.num_patients) {
            patientsSum += value.num_patients;
          }
          if (value && value.tot_benes) {
            beneSum += value.tot_benes;
          }
        });
        return {
          num_patients: patientsSum,
          tot_benes: beneSum,
          toString: () => {
            return `${patientsSum && beneSum ? patientsSum / beneSum : 0}`;
          }
        };
      },
      valueFormatter: ratioToPercentFormatter,
      cellStyle: { textAlign: 'right' }
    }
  ];
  const autoSizeStrategy = {
    type: 'fitCellContents'
  } as SizeColumnsToContentStrategy;

  const onGridReady = params => {
    params.api.sizeColumnsToFit();
  };

  const autoGroupColumnDef = {
    headerName: 'Group',
    minWidth: 220,
    filter: 'agGroupColumnFilter'
  };

  const colKeys = columnDefs.map(col => {
    if (col.field !== undefined) {
      return col.field;
    }
    return '';
  });

  function shouldRowBeSkipped(params) {
    if (!params.node.data) return true;
    else return false;
  }

  return (
    <div style={{ width: '100%', minHeight: '40rem' }}>
      <div
        style={{
          display: 'flex',
          marginBottom: '1rem',
          justifyContent: 'center',
          height: '6rem'
        }}
      >
        <div style={{ display: 'flex', flex: 1 }}>
          <label id="state-dropdown-label" style={{ marginRight: 2 }}>
            State:
          </label>
          <OpensearchGenericTypeahead
            id="state-dropdown"
            isMulti={false}
            database="raw"
            schema="prod_apcd"
            table_name="patient_claim_detail_zip5_view"
            placeholder="Select States"
            columnNames="main_individual_provider_computed_state"
            value={selectedState}
            onChange={handleStateChange}
            aria-labelledby="state-dropdown-label"
          />
        </div>
        <div style={{ display: 'flex', flex: 1 }}>
          <label id="county-dropdown-label" style={{ marginRight: 2 }}>
            County:
          </label>
          <OpensearchGenericTypeahead
            id="county-dropdown"
            isMulti={true}
            database="raw"
            schema="prod_apcd"
            table_name="patient_claim_detail_zip5_view"
            placeholder="Select Counties"
            columnNames="main_individual_provider_computed_county"
            value={selectedCounties}
            onChange={handleCountyChange}
            aria-labelledby="county-dropdown-label"
          />
        </div>
        <div style={{ display: 'flex', flex: 1 }}>
          <label
            id="provider-specialty-dropdown-label"
            style={{ marginRight: 2 }}
          >
            Provider Specialty:
          </label>
          <OpensearchGenericTypeahead
            id="provider-specialty-dropdown"
            isMulti={true}
            database="raw"
            schema="prod_apcd"
            table_name="patient_claim_detail_zip5_view"
            placeholder="Select Provider Specialties"
            columnNames="main_individual_provider_primary_specialty"
            value={selectedProviderSpecialties}
            onChange={handleProviderSpecialtyChange}
            aria-labelledby="provider-specialty-dropdown-label"
          />
        </div>
      </div>
      {selectedState.value && (
        <div
          className="ag-theme-alpine"
          style={{ width: '100%', height: '60vh' }}
        >
          <AgGridReact
            ref={gridApiRef}
            rowData={rowData}
            rowGroupPanelShow={'always'}
            grandTotalRow={'top'}
            columnDefs={columnDefs as ColDef[]}
            autoGroupColumnDef={autoGroupColumnDef}
            defaultCsvExportParams={{
              allColumns: true,
              columnKeys: colKeys,
              shouldRowBeSkipped: shouldRowBeSkipped
            }}
            suppressAggFuncInHeader={true}
            onGridReady={onGridReady}
            autoSizeStrategy={autoSizeStrategy}
            loading={loading}
            loadingOverlayComponent={() => (
              <FontAwesomeIcon icon={faSpinner as IconProp} spin size="lg" />
            )}
          />
        </div>
      )}
    </div>
  );
};

export const useGetApcdCoverageMetricsByState = (
  requiredFilter,
  optionalFilters
) => {
  const enabled =
    requiredFilter.value !== null && requiredFilter.value !== undefined;
  return useQuery({
    queryKey: [
      'account',
      'apcd_coverage_metrics_by_state',
      requiredFilter,
      optionalFilters
    ],
    queryFn: () =>
      getApcdCoverageMetricsByState(requiredFilter, optionalFilters, enabled),
    enabled: enabled // Enable query only if a state has been selected
  });
};

export const getApcdCoverageMetricsByState = async (
  requiredFilter,
  optionalFilters,
  enabled
) => {
  const params = { [requiredFilter.column]: requiredFilter.value };

  if (enabled) {
    optionalFilters.forEach(filter => {
      if (filter.value.length > 0) {
        params[filter.column] = [];
        filter.value.forEach(item => {
          params[filter.column].push(item.value);
        });
      }
    });
    const res = await accountApi.get<ApcdCoverageMetricsResponse>(
      `dataset/apcd_coverage_metrics_by_state/`,
      { params: params }
    );
    if (!res) {
      throw Error('Account API failed to respond. Please try again.');
    }
    handleServiceUnavailable(res);

    return res.data.coverage_metrics;
  } else {
    return [];
  }
};
